<template>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="color != 'none'"
          :color="color || 'color3'"
          v-on="on"
          small
          icon
          :loading="loading"
          :disabled="disabled"
        >
          <v-icon>{{  icon || 'fas fa-eye' }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          {{agreement}} History
        </v-toolbar>
        <v-card-text class="px-0">
          <template v-if="pName">
            <div class="title pl-3">{{ pName }}</div>
            <v-divider></v-divider>
          </template>
          <v-window v-model="window" touchless>
            <v-window-item :key="0">
              <v-data-table
                :class="{ 'hide-head': $vuetify.breakpoint.xsOnly }"
                :headers="[
                  {text: 'Sent', value: 'dtSent'},
                  {text: ' Date Signed', value: 'dtSigned'},
                  {text: '', value: 'view'},
                ]"
                :items="pAgree"
                item-key="id"
                :hide-default-footer="pAgree.length < 10"
                :loading="loading"
                disable-sort
              >
                <template v-slot:[`item.dtSent`]="{item}">
                  <div>{{item.dtSent | unixToLocalDt}}</div>
                  <div class="caption grey--text">{{item.to | formatArray}}</div>
                </template>
                <template v-slot:[`item.dtSigned`]="{item}">
                  <div v-if="item.dtSigned">{{item.dtSigned | unixToLocalDt}}</div>
                  <div v-else>Unsigned</div>
                </template>
                <template v-slot:[`item.view`]="{item}">
                  <v-btn
                    color="color3"
                    small
                    icon
                    @click.stop="openAgreement(item.id)"
                  >
                    <v-icon>fas fa-eye</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-window-item>
            <v-window-item :key="1">
              <div class="px-3 pt-2">
                <div class="text-subtitle-2">Select emails on file or enter additional email addresses</div>
                <v-checkbox
                  label="Use Email(s) On File"
                  v-model="onFile"
                  hide-details
                  color="success"
                ></v-checkbox>
                <v-expand-transition>
                  <v-combobox
                    class="mt-3"
                    v-if="showAdd"
                    v-model="iRecipients"
                    :search-input.sync="search"
                    :items="[]"
                    color="color3"
                    item-color="color3"
                    multiple
                    persistent-hint
                    small-chips
                    deletable-chips
                    label="Additional Recipients"
                    hint="Enter an email addresses to send to"
                    :disabled="loading"
                  >
                    <template slot="no-data" v-if="validEmail">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Press <kbd>enter</kbd> to add this email
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-expand-transition>
                <v-expand-transition>
                  <div class="text-end" v-if="!showAdd">
                    <v-btn color="color3" text x-small @click.stop="showAdd = true">add address</v-btn>
                  </div>
                </v-expand-transition>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid class="py-0">
          <v-row class="pa-0">
            <v-col cols="12" class="pa-0">
              <v-window v-model="window" touchless>
                <v-window-item :key="0">
                  <div class="justify-space-between d-flex">
                    <v-btn
                      color="color3"
                      text
                      @click.stop="window = 1"
                    >resend</v-btn>
                    <qr-dialog
                      color="color3"
                      v-if="qrUrl"
                      :url="qrUrl"
                    ></qr-dialog>
                  </div>
                </v-window-item>
                <v-window-item :key="1">
                  <div class="justify-space-between d-flex">
                    <v-btn
                      color="color3Text color3--text"
                      small
                      fab
                      @click.stop="window = 0"
                      :disabled="loading"
                    >
                      <v-icon>fas fa-caret-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="success"
                      :disabled="!valid"
                      :loading="loading"
                      @click.stop="sendThem"
                    >
                      Send
                    </v-btn>
                  </div>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { emailRegex } from '@/classes/HelperFunctions'
import { mapGetters } from 'vuex'
const QrDialog = () => import('@/components/Utils/QRDialog')

export default {
  props: ['disabled', 'playerProfileId', 'agreementId', 'color', 'agreement', 'noTournamentAllowed', 'playerName', 'icon'],
  data () {
    return {
      dialog: false,
      loading: false,
      pAgree: [],
      onFile: true,
      iRecipients: [],
      showAdd: false,
      window: 0,
      search: null
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    player () {
      return this.tournament && this.tournament.activePlayers.find(f => f.ppId === this.playerProfileId)
    },
    pName () {
      return this.player ? this.player.name : this.playerName
    },
    players () {
      return this.tournament.activePlayers
    },
    validEmail () {
      return this.search && this.search.match(emailRegex)
    },
    valid () {
      return this.onFile || this.iRecipients.length > 0
    },
    dto () {
      return this.playerProfileId && (this.tournament || this.noTournamentAllowed) && (this.player || this.playerName) && this.agreementId && this.valid && {
        playerProfileId: this.playerProfileId,
        tournamentId: this.tournament ? this.tournament.id : 0,
        agreementId: this.agreementId,
        name: this.tournament ? this.player.name : this.playerName,
        emailsOnFile: this.onFile,
        altEmailList: this.iRecipients.map(m => {
          return {
            name: this.tournament ? this.player.name : this.playerName,
            email: m
          }
        })
      }
    },
    qrUrl () {
      return this.pAgree.length > 0 ? `${window.location.origin}/agreement/${this.pAgree[0].id}` : false
    }
  },
  methods: {
    viewPlayer () {
      this.loading = true
      this.$VBL.agreements.tournament.player(this.agreementId, this.playerProfileId)
        .then(r => {
          this.pAgree = r.data
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    openAgreement (id) {
      window.open(`${window.location.origin}/agreement/${id}`, '_blank')
    },
    open () {
      this.dialog = true
    },
    sendThem () {
      if (!this.dto) return
      this.loading = true
      this.$VBL.agreements.tournament.send({ toBeSent: [this.dto], addNote: this.addNote })
        .then(r => {
          if (this.tournament) this.tournament.agreementStatuses = r.data
          this.viewPlayer()
          this.window = 0
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (v) {
      v && this.viewPlayer()
      this.window = 0
    }
  },
  components: {
    QrDialog
  }
}
</script>
<style scoped>
.hide-head >>> thead {
  display: none;
}
</style>
